import "./helpers/sentry";

// Workarounds for various browser specific issues
import "./quirks/facebook";
import "./quirks/bing-with-adblocker";
import "./quirks/storage";

// Load jQuery and plugins and make it global
import $ from "./helpers/leaked-jquery";
import Cookies from "js-cookie";
import "jquery-unveil";

// Load LuxSwiper and make it global
import { swiperScroll, swiperSlider } from "./classes/LuxSwiper";
window.swiperScroll = swiperScroll;
window.swiperSlider = swiperSlider;

import { Reorder } from "./classes/Reorder";
window.Reorder = Reorder;

import { MembershipOffer } from "./classes/MembershipOffer";
window.MembershipOffer = MembershipOffer;

// Load global helpers
import "./helpers/misc";
import {
    validateEmailSimple,
    validateEmail,
    validateEmailRegex,
} from "./helpers/utils";
window.validateEmailSimple = validateEmailSimple;
window.validateEmail = validateEmail;
window.validateEmailRegex = validateEmailRegex;
import "./helpers/header_b";

// Load all application components
import "./components/address-validation";
import "./components/basket";
import "./components/customer-visit";
import "./components/first-time-visitor";
import "./components/lux-accordions";
import "./components/lux-dropdown";
import "./components/lux-expandable";
import "./components/lux-popup";
import "./components/lux-select";
import "./components/MobileMenu";
import "./components/order";
import "./components/shrinking-navigation-bar";
import "./components/rate-product-item";
import "./components/membership-card";

if (import.meta.hot) {
    import.meta.hot.accept();
}
